import axiosClient from "./axiosWrapper";
import {dateInputToDateString} from "../utils/time";

export const getLogs = async (userId, signalId, messageId, date) => {
  let params = {}
  if (userId) {
    params.userId = userId
  }
  if (signalId) {
    params.signalId = signalId
  }
  if (messageId) {
    params.messageId = messageId
  }
  if (date) {
    let startTime = new Date(date)
    startTime.setDate(startTime.getDate() - 1)
    let endTime = new Date(date)
    endTime.setDate(endTime.getDate() + 1)

    params.startDate  = dateInputToDateString(startTime.toISOString())
    params.endDate = dateInputToDateString(endTime.toISOString())
  }
  let result = await axiosClient().post(`/logs`, params)

  return result
}